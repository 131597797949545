<template>
  <transition name="Fade">
    <div v-if="isShow" class="black-message">
      <span class="msg" v-html="message"></span>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      message: "",
      isShow: false,
    };
  },
  methods: {
    show(message, time = 1500) {
      this.isShow = true;
      this.message = message;
      setTimeout(() => {
        this.isShow = false;
        this.message = "";
        // 传入的显示时间
      }, time);
    },
  },
};
</script>
<style lang="scss" scoped>
.black-message {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 24px 21px;
  color: #ffffff;
  border-radius: 8px;
  letter-spacing: 1px;
  font-weight: bold;
  background: rgba(37, 38, 45, 0.6);
  z-index: 100001;
  .msg {
    ::v-deep span {
      color: #ffb836;
    }
  }
}
.Fade-enter,
.Fade-leave-to {
  opacity: 0;
}

.Fade-enter-to,
.Fade-leave {
  opacity: 1;
}

.Fade-enter-active,
.Fade-leave-active {
  transition: all 0.5s;
}
</style>
