<template>
  <div class="versionComparison">
    <el-dialog
      :visible.sync="dialogVisible"
      width="785px"
      :append-to-body="true"
      :lock-scroll="true"
    >
      <span slot="title">
        <div class="model-title">文通云 | 精准教学大数据系统 版本对比</div>
        <div class="head-tips">
          <span v-if="schoolInfo.expireTime">
            <img src="@/assets/versions/time.png" alt="" />
            订阅有效期至：{{ timeToStr(schoolInfo.expireTime) }}
          </span>
          <span>
            <img src="@/assets/versions/phone.png" alt="" /> 联系我们：+86 158
            0280 8311
          </span>
        </div>
      </span>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="name" label="功能模块" align="center">
          <template #header>
            <div class="icon-title">
              <span>功能模块</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="基础版" align="center">
          <template #header>
            <div class="icon-title">
              <img src="@/assets/versions/versions_1.png" alt="" />
              <span>基础版</span>
            </div>
            <div v-if="userInfo.schoolVersion == 1" class="tips-tab">
              正在使用
            </div>
          </template>
          <template slot-scope="{ row }">
            <img
              v-if="row.basics == 1"
              src="@/assets/versions/check.png"
              alt=""
            />
            <i v-else class="el-icon-minus"></i>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="标准版" align="center">
          <template #header>
            <div class="icon-title">
              <img src="@/assets/versions/versions_2.png" alt="" />
              <span>标准版</span>
            </div>
            <div v-if="userInfo.schoolVersion == 2" class="tips-tab">
              正在使用
            </div>
          </template>
          <template slot-scope="{ row }">
            <img
              v-if="row.standard == 1"
              src="@/assets/versions/check.png"
              alt=""
            />
            <i v-else class="el-icon-minus"></i>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="精准教学" align="center">
          <template #header>
            <div class="icon-title">
              <img src="@/assets/versions/versions_3.png" alt="" />
              <span>精准教学</span>
            </div>
            <div v-if="userInfo.schoolVersion == 3" class="tips-tab">
              正在使用
            </div>
          </template>
          <template slot-scope="{ row }">
            <img
              v-if="row.teaching == 1"
              src="@/assets/versions/check.png"
              alt=""
            />
            <i v-else class="el-icon-minus"></i>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
const list = [
  {
    name: "智慧网阅",
    basics: 1,
    standard: 1,
    teaching: 1,
  },
  {
    name: "成绩报表",
    basics: 1,
    standard: 1,
    teaching: 1,
  },
  {
    name: "移动App",
    basics: 1,
    standard: 1,
    teaching: 1,
  },
  {
    name: "资源中心",
    basics: 0,
    standard: 1,
    teaching: 1,
  },
  {
    name: "工作台",
    basics: 0,
    standard: 1,
    teaching: 1,
  },
  {
    name: "成绩分析报告",
    basics: 0,
    standard: 1,
    teaching: 1,
  },
  {
    name: "试卷上传",
    basics: 0,
    standard: 1,
    teaching: 1,
  },
  {
    name: "App掌上作业",
    basics: 0,
    standard: 1,
    teaching: 1,
  },
  {
    name: "知识点自动标注",
    basics: 0,
    standard: 0,
    teaching: 1,
  },
  {
    name: "变式题主动推送",
    basics: 0,
    standard: 0,
    teaching: 1,
  },
  {
    name: "智能学情组卷",
    basics: 0,
    standard: 0,
    teaching: 1,
  },
  {
    name: "知识点讲评报告",
    basics: 0,
    standard: 0,
    teaching: 1,
  },
];
import { getStore } from "@/core/util/store";
import { timeToStr } from "@/core/util/util";
export default {
  name: "VersionComparison",
  data() {
    return {
      dialogVisible: false,
      tableData: list,
      schoolVis: "",
      userInfo: {},
      schoolInfo: {},
    };
  },
  created() {},
  methods: {
    timeToStr(val) {
      return timeToStr(val);
    },
    // timeToStr: timeToStr(),
    init(val) {
      this.userInfo = getStore({
        name: "userInfo",
      });
      this.schoolInfo = getStore({ name: "schoolInfo" }) || {};
      this.dialogVisible = true;
      //   console.log(val);
      this.schoolVis = val;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 18px;
    .el-table__header th {
      background-color: #ffffff;
    }
    .tips-tab {
      font-size: 12px;
      color: #2474ed;
      border: 1px solid #2474ed;
      border-radius: 4px;
      background: #ffffff;
      display: inline-block;
      padding: 3px 4px;
      font-weight: 500;
      line-height: 12px;
    }
    .icon-title {
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      font-size: 16px;
      margin-bottom: 4px;
      vertical-align: text-bottom;
      img {
        width: 18px;
        vertical-align: text-bottom;
        margin-right: 4px;
      }
    }
  }
  .el-dialog__header {
    height: 100px;
    background: url("~@/assets/versions/model-title.png") no-repeat;
    background-size: 100% 100%;
    padding: 24px 18px;
    border-bottom: none;
    .el-dialog__headerbtn {
      top: 28px;
      transform: translateY(0);
    }
    .model-title {
      font-size: 18px;
      color: #0a1119;
      font-weight: 600;
    }
    .head-tips {
      margin-top: 10px;
      img {
        vertical-align: text-bottom;
      }
      span {
        margin-right: 12px;
      }
    }
  }
}
// .versionComparison {
// }
</style>
