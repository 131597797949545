import { Message } from "element-ui";
const state = {
  //标注知识点,匹配变式训练题页面的数据列表
  examQuestionList: [],
  // 当前试卷的基本信息
  examPaper: {},
  //变式训练题的列表数据
  variationList: [],
  // 当前选中的试题
  indexQuestion: {},
  // 题型列表
  examTypeList: [],
  // 当前试题总盒子的高度
  questionHeight: 0,
  //当前知识点列表
  paperKnowList: [],
  // 当前知识点树形结构
  paperTreeKnowList: [],
  // 科目所有的题型列表
  questionTypeList: [],
  // 获取当前考试/学段/所有科目
  subjectList: [],
  subjectId: "",
  //变式训练题更新
  trainingType: true,
  // 当前分层变式题类型
  layeredTypes: 3,
  // 盤點下頁面更新
  newView: 1,
  // 当前教师的权限
  teacherAuthority: "",
  // word 裁题 划题dom
  domList: [],
};
const actions = {};
const mutations = {
  SET_DOM_LIST(state, value) {
    state.domList = value;
  },
  INIT_DOM_LIST(state) {
    state.domList = [];
  },
  SET_TEACHER_AUTH(state, value) {
    state.teacherAuthority = value;
  },
  SET_LAYER_TYPS(state, value) {
    state.layeredTypes = value;
  },
  SET_NEW_VIEW(state) {
    state.newView = state.newView++;
  },
  clearAll(state) {
    state.examQuestionList = [];
    state.examPaper = {};
    state.questionHeight = 0;
    state.layeredTypes = 3;
    state.examQuestionList = [];
    state.indexQuestion = {};
    state.examTypeList = [];
    state.paperKnowList = [];
    state.paperTreeKnowList = [];
  },
  //切换变式训练题
  setQuestionVariation(state, obj) {
    state.examQuestionList.map((index) => {
      if (index.id == obj.id) {
        state.indexQuestion = index;
      }
    });
  },
  //设置知识点
  setKnowList(state, obj) {
    state.paperKnowList = obj.paperKnowList;
    state.paperTreeKnowList = obj.paperTreeKnowList;
    // console.log(state.paperKnowList);
    // console.log(state.paperTreeKnowList);
  },
  // 跳转至下一题
  nextQuestion(state, id) {
    // indexQuestion
    state.examQuestionList.map((item, index) => {
      if (item.examQuestionId == id) {
        if (index == state.examQuestionList.length - 1) {
          Message({
            message: "已经是最后一题了,无法跳转！",
            type: "warning",
          });
          return;
        }
        state.indexQuestion = state.examQuestionList[index + 1];
      }
    });
  },
  //本地添加 删除分层变式训练题
  changeQuestion(state, obj) {
    // console.log(obj);
    state.examQuestionList.map((index) => {
      if (index.examQuestionId == obj.examQuestionId) {
        if (obj.type == 1) {
          obj.type = obj.changeType;
          index.examQuestionExtendList.push(obj);
        } else {
          index.examQuestionExtendList = index.examQuestionExtendList.filter(
            (item) =>
              item.examQuestionOpt != obj.examQuestionOpt ||
              (item.examQuestionOpt == obj.examQuestionOpt &&
                item.difficultyType != obj.difficultyType)
          );
        }
      }
    });
    state.variationList = setVariation(state.examQuestionList);
  },
  //根据是题id修改
  changeQuestionId(state, obj) {
    // console.log(obj);
    state.examQuestionList.map((index) => {
      if (index.examQuestionId == obj.id) {
        delete obj.id;
        Object.assign(index, obj);
      }
    });
  },
  //根据试卷试题id修改
  changeIdQuestion(state, obj) {
    // console.log(obj);
    state.examQuestionList.map((index) => {
      if (index.id == obj.id) {
        Object.assign(index, obj);
      }
    });
  },
  // 更新审题状态列表
  SET_CHANGE_AUDIT(state, obj) {
    state.examQuestionList = state.examQuestionList.map((index) => {
      if (index.id == obj.id) {
        Object.assign(index, obj);
      }
      return index;
    });
    state.variationList = setVariation(state.examQuestionList);
  },
  //更新变式训练题数据  传值方式只剩 examQuestionOpt 新增就直接在examQuestionOpt【n】1-5 进行增删改查
  putTraining(state, val) {
    let data = JSON.parse(JSON.stringify(val));
    state.examQuestionList.map((index) => {
      if (index.id == val.id) {
        index.statusTraining = 1;
        index.statusReplaceTraining = 0;

        index.examQuestionExtendList.push({
          examQuestionOpt: data.examQuestionOpt,
          type: data.type,
        });
      }
    });

    state.variationList = setVariation(state.examQuestionList);
  },
  //取消变式训练题
  delTraining(state, val) {
    state.examQuestionList.map((index) => {
      if (index.id == val.id) {
        index.examQuestionExtendList = index.examQuestionExtendList.filter(
          (item) => item.examQuestionOpt != val.examQuestionOpt
        );
      }
    });
    state.variationList = setVariation(state.examQuestionList);
  },
  //设置当前试卷数据
  setExamPaper(state, val) {
    //试题列表
    state.examQuestionList = val.examQuestionVOList;
    // 试卷信息
    state.examPaper = val.examPaper;
    //变式训练题的右侧的信息数据
    state.variationList = setVariation(state.examQuestionList, 1);
    // console.log(state.variationList);
  },
  //设置当前浮动高度
  setQuestionHeight(state, val) {
    // console.log("触发了这个方法");
    state.questionHeight = val + 80 + "px";
    // console.log(state.questionHeight);
  },
  //设置当前试题
  setIndexQuestion(state, val) {
    state.indexQuestion = val;
  },
  //设置题型列表
  setExamTypeList(state, val) {
    state.examTypeList = val;
  },
  //设置所有题型列表
  SET_QUESTION_TYPE_LIST(state, val) {
    state.questionTypeList = val;
  },
  //设置科目列表
  SET_SUBJECT_LIST(state, val) {
    state.subjectList = val;
  },
  SET_SUBJECT_ID(state, val) {
    state.subjectId = val;
  },
};
const getters = {
  GET_TEACHER_AUTH(state) {
    return state.teacherAuthority;
  },
  GET_SUBJECT_ID(state) {
    return state.subjectId;
  },
  GET_NEW_VIEW(state) {
    return state.newView;
  },
  GET_LAYER_TYPS(state) {
    return state.layeredTypes;
  },
  GET_SUBJECT_Q_LIST(state) {
    return state.subjectList;
  },
  GET_QUESTION_TYPE_LIST(state) {
    return state.questionTypeList;
  },
  getPaperKnowList(state) {
    return state.paperKnowList;
  },
  getPaperTreeKnowList(state) {
    return state.paperTreeKnowList;
  },
  getExamQuestion(state) {
    return state.examQuestionList;
  },
  getExamPaper(state) {
    return state.examPaper;
  },
  getQuestionHeight(state) {
    return state.questionHeight;
  },
  getIndexQuestion(state) {
    return state.indexQuestion;
  },
  getExamTypeList(state) {
    return state.examTypeList;
  },
  getVariationList(state) {
    return state.variationList;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
//根据当前列表判断当前试题是否已标注知识点
function setVariation(list, type) {
  let quArr = [];
  // statusReplaceKnowledge: 0
  // statusReplaceTraining: 0

  list.forEach((i, a) => {
    // let str = "examQuestionOpt";
    //判断是否规定项内所有题都锁定了变式训练题
    // let typeIndex = 0;
    // let trainingType = false;
    // for (let index = 0; index < 5; index++) {
    //   let indexStr = str + (index + 1);
    //   if (i[indexStr] > 0) {
    //     typeIndex++;
    //   }
    // }
    let arr = [];
    // 第一次需要重新组装数据
    if (type) {
      arr = i.examQuestionExtendList.map((item) => {
        let id = item.id;
        item.id = item.examQuestionOpt;
        // 重新赋值id
        item.qId = id;
        return item;
      });
    } else {
      arr = i.examQuestionExtendList;
    }

    quArr.push({
      val: a,
      type:
        i.examQuestionExtendList.filter(
          (item) => item.type == 1 || item.type == 3
        ).length > 0
          ? true
          : false,
      id: i.id,
      examQuestionOrder: i.examQuestionOrder,
      statusReplaceTraining: i.statusReplaceTraining,
      statusReplaceKnowledge: i.statusReplaceKnowledge,
      examQuestionExtendList: JSON.parse(JSON.stringify(arr)),
      nums: i.examQuestionExtendList.filter(
        (item) => item.type == 1 || item.type == 3
      ).length,
    });
  });
  return quArr;
}
