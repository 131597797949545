import request from "@/core/services/axios";
import qs from "qs";
export function scanrecordAnswersheet(query) {
  return request({
    url: "/scan/scanrecord/answersheet",
    method: "get",
    params: query,
  });
}

export function scanrecordbycondition(query) {
  return request({
    url: "/scan/scanrecord/get/scanrecordbycondition",
    method: "get",
    params: query,
  });
}

export function getScanrecordclass(query) {
  return request({
    url: "/scan/scanrecord/get/scanrecordclass",
    method: "get",
    params: query,
  });
}
// 提交主观题分数(批量提交未评为0分)
export function subquestionexceptionsBatch(query) {
  return request({
    url: "/scan/scanrecorderror/submit/subquestionexceptions/batch",
    method: "get",
    params: query,
  });
}

// 替换答题卡图片
export function replaceUrls(query) {
  return request({
    url: "/scan/scanrecord/replace/urls",
    method: "get",
    params: query,
  });
}

// 设置-取消-学生缺考状态
export function scanrecordAbsent(query) {
  return request({
    url: "/scan/scanrecord/set/scanrecord/absent",
    method: "get",
    params: query,
  });
}

// 按批次的分页列表接口
export function scanrecordbatch(query) {
  return request({
    url: "/scan/scanrecord/get/scanrecordbatch",
    method: "get",
    params: query,
  });
}

// 按考场查询的分页列表接口
export function scanrecordexamroom(query) {
  return request({
    url: "/scan/scanrecord/get/scanrecordexamroom",
    method: "get",
    params: query,
  });
}

// 按考场查询的分页列表接口
export function scanrecordAll(query) {
  return request({
    url: "/scan/scanrecorderror/all",
    method: "get",
    params: query,
  });
}

// 获取指定异常列表全部数据
export function getErrorList(query) {
  return request({
    url: "/scan/scanrecorderror/get/errorlist",
    method: "get",
    params: query,
  });
}

// 获取Ab卷异常列表全部数据
export function getABtype(query) {
  return request({
    url: "/scan/scanrecorderror/get/abtype",
    method: "get",
    params: query,
  });
}

// 获取Ab卷异常数量
export function getABtypeNum(query) {
  return request({
    url: "/scan/scanrecorderror/get/abtype/num",
    method: "get",
    params: query,
  });
}

// 退回答题卡
export function scanrecordBack(query) {
  return request({
    url: "/scan/scanrecord/back",
    method: "get",
    params: query,
  });
}

//清空已删除的图像不完整的答题卡列表数据
export function deleteRecyclebin(data) {
  return request({
    url: "/scan/scanrecorderror/delete/recyclebin",
    method: "put",
    data: data,
  });
}

//批量提交A-B卷异常答题卡数据
export function abtypeBatch(data) {
  return request({
    url: "/scan/scanrecorderror/abtype/batch",
    method: "post",
    data: data,
  });
}

// 批量设置图像不完整的答题卡为完整(会重新识别，大概率改为定位异常)
export function setErrortype(data) {
  return request({
    url: "/scan/scanrecorderror/set/errortype",
    method: "put",
    data: data,
  });
}

// 批量删除图像不完整的答题卡为完整(会重新识别，大概率改为定位异常)
export function deletebatch(data) {
  return request({
    url: "/scan/scanrecorderror/deletebatch",
    method: "put",
    data: data,
  });
}

// 获取已删除的图像不完整的答题卡列表数据
export function getRecyclebin(query) {
  return request({
    url: "/scan/scanrecorderror/get/recyclebin",
    method: "get",
    params: query,
  });
}

// 模糊查询当次考试的学生列表接口
export function getexamstudent(query) {
  return request({
    url: "/scan/scanrecorderror/getexamstudent",
    method: "get",
    params: query,
  });
}

// 获取异常数量
export function scanrecordunionErrortypeNums(query) {
  return request({
    url: "/scan/scanrecordunion/errortype/nums",
    method: "get",
    params: query,
  });
}

// 联考-异常已处理-未处理数量
export function appointErrortypeNums(query) {
  return request({
    url: "/scan/scanrecordunion/appoint/errortype/nums",
    method: "get",
    params: query,
  });
}

// 获取当前考试的科目列表
export function getSubjects(query) {
  return request({
    url: "/scan/scanrecorderror/get/subjects",
    method: "get",
    params: query,
  });
}

// 获取选做题题组
export function chooseBase(query) {
  return request({
    url: "/scan/scanrecorderror/choose/base",
    method: "get",
    params: query,
  });
}

// 获取选做题题组数量
export function questionCount(query) {
  return request({
    url: "/scan/scanrecorderror/choose/question/count",
    method: "get",
    params: query,
  });
}

// 选做题处理
export function blankList(query) {
  return request({
    url: "/mark/subjective/blank/list",
    method: "put",
    data: query,
  });
}

// 选做题列表
export function chooseQuestionList(query) {
  return request({
    url: "/scan/scanrecorderror/choose/question/page",
    method: "get",
    params: query,
  });
}

// 按批次的分页列表接口
export function scanrecordPage(query) {
  return request({
    url: "/scan/scanrecord/page",
    method: "get",
    params: query,
  });
}

// 获取下载版本
export function getClientVersion(query) {
  return request({
    url: "/scan/scanclient/getClientVersion",
    method: "get",
    params: query,
  });
}

// 查询异常数量
export function errortypeNums(query) {
  return request({
    url: "/scan/scanrecorderror/errortype/nums",
    method: "get",
    params: query,
  });
}

// 获取缺卡标记数量
export function absenterrorNums(query) {
  return request({
    url: "/scan/scanrecorderror/absenterror/nums",
    method: "get",
    params: query,
  });
}

// 获取校验模板扫描记录
export function examineScan(query) {
  return request({
    url: "/scan/scanrecord/get/examineScan",
    method: "get",
    params: query,
  });
}

// 获取已处理-未处理客观题区域以及数量
export function basequestionexceptions(query) {
  return request({
    url: "/scan/scanrecorderror/get/basequestionexceptions",
    method: "get",
    params: query,
  });
}

// 获取客观题异常数据列表全部数据
export function objectivequestionexceptions(query) {
  return request({
    url: "/scan/scanrecorderror/get/objectivequestionexceptions",
    method: "get",
    params: query,
  });
}

// 获取扫描人数
export function scanrecordExampaperscaninfo(query) {
  return request({
    url: "/scan/scanrecord/exampaperscaninfo",
    method: "get",
    params: query,
  });
}

// 下载扫描记录列表
export function downloadScanrecord(query) {
  return request({
    url: "/scan/scanrecord/download/scanrecord",
    method: "get",
    params: query,
  });
}

// 下载扫描记录列表 联考
export function uniondownloadScanrecord(query) {
  return request({
    url: "/scan/scanrecordunion/download/scanrecord",
    method: "get",
    params: query,
  });
}

// 获取按学校的扫描记录统计数据
export function scanrecordschool(query) {
  return request({
    url: "/scan/scanrecord/get/scanrecordschool",
    method: "get",
    params: query,
  });
}

// 获取主观题异常数量
export function basesubquestionexceptions(query) {
  return request({
    url: "/scan/scanrecorderror/get/basesubquestionexceptions",
    method: "get",
    params: query,
  });
}

// 获取主观题-分值列表
export function scorelist(query) {
  return request({
    url: "/scan/scanrecorderror/get/scorelist",
    method: "get",
    params: query,
  });
}

// 获取主观题-填空题数量
export function isfillblank(query) {
  return request({
    url: "/scan/scanrecorderror/get/isfillblank",
    method: "get",
    params: query,
  });
}

// 还原已删除图像
export function reductioRecyclebin(data) {
  return request({
    url: "/scan/scanrecorderror/reduction/recyclebin",
    method: "put",
    data: data,
  });
}

// 批量删除定位异常的答题卡
export function abnormalPositioning(data) {
  return request({
    url: "/scan/scanrecorderror/delete/abnormal/positioning",
    method: "put",
    data: data,
  });
}

// 批量重新识别定位异常答题卡(一般是修改模板后)
export function redistinguish(data) {
  return request({
    url: "/scan/scanrecorderror/redistinguish",
    method: "put",
    data: data,
  });
}

// 批量设置学科异常答题卡为正常
export function settonormal(data) {
  return request({
    url: "/scan/scanrecorderror/settonormal",
    method: "put",
    data: data,
  });
}

// 修改图片地址  并重新裁切
export function scanScanrecord(data) {
  return request({
    url: "/scan/scanrecord",
    method: "put",
    data: data,
  });
}

// 批量设置标题异常答题卡为正常
export function settitlenormal(data) {
  return request({
    url: "/scan/scanrecorderror/settitlenormal",
    method: "put",
    data: data,
  });
}

// 删除扫描记录(支持批量)
export function deleteScanrecord(data) {
  return request({
    url: "/scan/scanrecord/delete/scanrecord",
    method: "put",
    data: data,
  });
}

// 修改定位异常urls(旋转或正反面互换)
export function updateurls(data) {
  return request({
    url: "/scan/scanrecorderror/updateurls",
    method: "put",
    data: data,
  });
}

// 批量转移定位异常答题卡的学科
export function transferSubject(data) {
  return request({
    url: "/scan/scanrecorderror/transfer/subject",
    method: "post",
    data: data,
  });
}

// 根据试卷id-清空扫描记录
export function deleteScanrecordAll(data) {
  return request({
    url: "/scan/scanrecord/delete/scanrecord/all",
    method: "post",
    data: data,
  });
}

// 联考根据试卷id-清空扫描记录
export function uniondeleteScanrecordAll(data) {
  return request({
    url: "/scan/scanrecordunion/delete/scanrecord/all",
    method: "post",
    data: data,
  });
}

// 核准无卡
export function approvalAbsent(data) {
  return request({
    url: "/scan/scanrecordmiss/approval/absent",
    method: "post",
    data: data,
  });
}

// 将考号填涂异常的答题卡分给具体的学生
export function setabnormalTostudent(data) {
  let dataObj = qs.stringify(data);
  return request({
    url: "/scan/scanrecorderror/setabnormal/tostudent?" + dataObj,
    method: "post",
    data: data,
  });
}

// 将考号重复的答题卡分给具体的学生
export function repeatexamstudentidTostudent(data) {
  let dataObj = qs.stringify(data);
  return request({
    url: "/scan/scanrecorderror/repeatexamstudentid/tostudent?" + dataObj,
    method: "post",
    data: data,
  });
}

// 批量重新识别考号填涂异常的答题卡
export function studentexamid(data) {
  return request({
    url: "/scan/scanrecorderror/redistinguish/studentexamid",
    method: "post",
    data: data,
  });
}

// 批量重新匹配考号重复的答题卡
export function repeatexamstudentidStudentexamid(data) {
  return request({
    url: "/scan/scanrecorderror/repeatexamstudentid/studentexamid",
    method: "post",
    data: data,
  });
}

// 批量提交缺考-非缺考的答题卡数据
export function absentornotabsentBatch(data) {
  return request({
    url: "/scan/scanrecorderror/absentornotabsent/batch",
    method: "post",
    data: data,
  });
}

// 批量设置客观题异常答题卡的答案并提交
export function submitObjectivequestionexceptions(data) {
  return request({
    url: "/scan/scanrecorderror/submit/objectivequestionexceptions",
    method: "post",
    data: data,
  });
}

// 重新识别
export function identifyagainBatch(data) {
  return request({
    url: "/scan/scanrecorderror/identifyagain/batch",
    method: "post",
    data: data,
  });
}

// 重新识别 定位异常
export function recognitionLocation(data) {
  return request({
    url: "/scan/scanrecorderror/recognitionLocation",
    method: "post",
    data: data,
  });
}

// 分页查询主观题-小题详情
export function subquestionexceptions(data, pageData) {
  let dataObj = qs.stringify(pageData);
  return request({
    url: "/scan/scanrecorderror/get/subquestionexceptions?" + dataObj,
    method: "post",
    data: data,
  });
}

// 提交主观题分数
export function submitSubquestionexceptions(data) {
  return request({
    url: "/scan/scanrecorderror/submit/subquestionexceptions",
    method: "post",
    data: data,
  });
}

// 将定位异常答题卡标记为异常卷(需要和学生绑定)
export function scanrecorderrorSetabnormal(data) {
  let dataObj = qs.stringify(data);
  return request({
    url: "/scan/scanrecorderror/setabnormal?" + dataObj,
    method: "post",
    // query: dataObj,
  });
}

// 自定义设置识别参数
export function parameterSave(data) {
  return request({
    url: "/scan/scanrecordrecognition/save",
    method: "post",
    data: data,
  });
}

// 自定义设置识别参数
export function parameterGet(data) {
  return request({
    url: "/scan/scanrecordrecognition/get",
    method: "get",
    params: data,
  });
}
