import { markTypeList } from "@/core/util/constdata";
const state = {
  // 当前标注的类型 当前在哪一步
  labelledType: 1,
  // 缓存下来用来判断用户是否已提交
  oldSheetMarkList: [],
  // 标注图片的列表
  sheetMarkList: [],
  // 当前图片
  indexSheetMark: {},
  // 当前项图层渲染列表
  sheetLineList: [],
  // 试题分数列表
  questionList: [],
  editListType: true,
  // 添加最后一道题题号保存
  questionEnd: 0,
};
const actions = {};
const mutations = {
  CLEAR_MARK_DATA(state) {
    state.labelledType = 1;
    state.oldSheetMarkList = [];
    state.sheetMarkList = [];
    state.sheetLineList = [];
    state.questionList = [];
    state.editListType = true;
  },
  // 最后一次题号输入
  SET_LAST_QUESTION(state, val) {
    // console.log(val);
    state.questionEnd = val;
  },
  // 设置追加区域的代码
  SET_PRIORITY(state, val) {
    let list = JSON.parse(val);
    let priority = list[0].priority;
    state.sheetMarkList.map((item) => {
      if (item.priority == priority) {
        item.markSubjective = val;
      }
    });
    // state.sheetLineList = list;
    // console.log(state.sheetMarkList);
    state.oldSheetMarkList.map((item) => {
      if (item.priority == priority) {
        item.markSubjective = val;
      }
    });
  },
  // 设置非当前项的 JSON修改
  SET_MARK(state, data) {
    state.sheetMarkList.map((item) => {
      if (item.id == data.id) {
        Object.assign(item, data);
      }
    });
    state.oldSheetMarkList.map((item) => {
      if (item.id == data.id) {
        Object.assign(item, data);
      }
    });
    // console.log(state.sheetMarkList);
  },
  // 设置当前线是否可以编辑
  SET_EDIT_LIST_TYPE(state, val) {
    state.editListType = val;
  },
  // 设置当前标注类型  线  矩形  三角形
  SET_LABELLED_TYPE(state, val) {
    state.labelledType = val;
  },
  // 设置一份数据  用来判断用户是否已经提交了
  SET_OLDSHEET_MARK_LIST(state, val) {
    state.oldSheetMarkList = val;
  },
  // 用户提交后 修改本地数据
  EDIT_OLDSHEET_MARK_LIST(state) {
    // console.log("触发了");
    state.oldSheetMarkList.map((item) => {
      // 修改列表中的值
      if (state.indexSheetMark.id == item.id) {
        markTypeList.map((i) => {
          if (state.labelledType == i.value) {
            item[i.key] = JSON.stringify(state.sheetLineList);
          }
        });
      }
    });
  },
  SET_SHEET_MARK_LIST(state, val) {
    state.sheetMarkList = val;
  },
  SET_INDEX_SHEET_MARK(state, val) {
    state.indexSheetMark = val;
  },
  //恢复数据到提交之前
  SET_LIST_GO_OLD(state) {
    state.sheetMarkList = JSON.parse(JSON.stringify(state.oldSheetMarkList));
    markTypeList.map((item) => {
      // 判断是当前项
      if (state.labelledType == item.value) {
        state.sheetMarkList.map((index) => {
          // 判断是当前页
          if (index.id == state.indexSheetMark.id) {
            state.sheetLineList = JSON.parse(index[item.key]);
          }
        });
      }
    });
    // console.log();
    // commit("SET_SHEET_LINE_LIST")
  },
  // 修改当前项的列表  并更新部分数据
  SET_SHEET_LINE_LIST(state, val) {
    state.sheetLineList = val;
    state.sheetMarkList.map((item) => {
      // 修改列表中的值
      if (state.indexSheetMark.id == item.id) {
        markTypeList.map((i) => {
          if (state.labelledType == i.value) {
            item[i.key] =
              state.sheetLineList.length == 0
                ? ""
                : JSON.stringify(state.sheetLineList);
          }
        });
      }
    });
  },
};
const getters = {
  // 获取最后一次题号
  GET_LAST_QUESTION(state) {
    let questionEnd = state.questionEnd;

    questionEnd = Number(questionEnd);
    if (!questionEnd || questionEnd == "NaN") {
      questionEnd = getNums(state);
    } else {
      questionEnd = parseInt(questionEnd) + 1;
    }
    return questionEnd;
  },
  GET_EDIT_LIST_TYPE(state) {
    return state.editListType;
  },
  GET_OLDSHEET_MARK_LIST(state) {
    return state.oldSheetMarkList;
  },
  GET_LABELLED_TYPE(state) {
    return state.labelledType;
  },
  GET_SHEET_MARK_LIST(state) {
    return state.sheetMarkList;
  },
  GET_INDEX_SHEET_MARK(state) {
    return state.indexSheetMark;
  },
  GET_SHEET_LINE_LIST(state) {
    return state.sheetLineList;
  },
  GET_QUESTION_LIST(state) {
    return state.questionList;
  },
  // 获取试题中的最大value
  GET_SHEET_QUESTION_MAX(state) {
    //  获取下所有的主观题  客观题列表
    let num = getNums(state);
    return num;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

function getNums(state) {
  let itemMarkSubjective = new Array();
  let itemMarkObjective = new Array();
  let questionMaxNum = 1;
  state.sheetMarkList.forEach((item) => {
    let indexMarkSubjective = new Array();
    let indexMarkObjective = new Array();
    // 主观题数组
    if (!item.markSubjective) {
      indexMarkSubjective = [];
    } else {
      indexMarkSubjective = JSON.parse(item.markSubjective);
    }
    // 客观题数组
    if (!item.markObjective) {
      indexMarkObjective = [];
    } else {
      indexMarkObjective = JSON.parse(item.markObjective);
    }
    itemMarkSubjective = itemMarkSubjective.concat(indexMarkSubjective);
    itemMarkObjective = itemMarkObjective.concat(indexMarkObjective);
  });
  itemMarkSubjective.forEach((item) => {
    item.questionNumList.forEach((index) => {
      if (index.value > questionMaxNum) {
        questionMaxNum = index.value;
      }
      // console.log(Number(index.examQuestionNumber));
      let examQuestionNumber = Number(index.examQuestionNumber);
      if (examQuestionNumber != "NaN") {
        if (Number(index.examQuestionNumber) > questionMaxNum) {
          questionMaxNum = examQuestionNumber;
        }
      }
    });
  });
  itemMarkObjective.forEach((item) => {
    item.questionNumList.forEach((index) => {
      if (index.value > questionMaxNum) {
        questionMaxNum = index.value;
      }
      //
      let examQuestionNumber = Number(index.examQuestionNumber);
      // console.log(examQuestionNumber);
      if (examQuestionNumber != "NaN") {
        if (Number(index.examQuestionNumber) > questionMaxNum) {
          questionMaxNum = examQuestionNumber;
        }
      }
    });
  });
  // console.log(itemMarkObjective);
  if (itemMarkSubjective.length > 0 || itemMarkObjective.length > 0) {
    questionMaxNum = Number(questionMaxNum) + 1;
  }
  return parseInt(questionMaxNum);
}
