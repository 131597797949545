import { setStore, getStore } from "@/core/util/store";
import { copyText } from "@/core/util/util";
const state = {
  //缓存一下需要暂存的页面
  keepArr: ["MyResIndex"],
  //请求错误提示
  errApi: {
    text: "错误内容",
    type: false,
  },
  //错误弹框
  errBox: {
    text: "错误内容",
    type: false,
  },
  // 学生进入时，是否header已经请求回来了
  headerType: false,
  // 当前是否为图片切题页面
  isPicDivide: false,
  // 当前是否为个人信息页面
  isUserInfo: false,
  // 当前浏览器的滚动条宽度
  scrollYWidth: 0,
  //当前选中的科目，知识点
  subjectKnow: {},
  // 阅卷监控 教师账号的情况下 缓存的教师账号权限
  teacherRoleList: [],
  // 缓存质量列表科目
  monitorSubjectIds: [],
  //  缓存监控进度科目列表
  qualitySubjectIds: [],
  // 联考的情况下  学校列表
  schoolList: [],
  // 顶部菜单栏
  menuBarList: [],
  // 版本更新
  notification: "",
};
const actions = {};
const mutations = {
  SET_ALL_COPY(state, val) {
    copyText(val);
  },
  setSubjectKnow(state, val) {
    state.subjectKnow = val;
    // setStore("subjectKnow", val);
    setStore({ name: "subjectKnow", content: val });
  },
  setScrollYWidth(state, val) {
    state.scrollYWidth = val;
  },
  SET_Notification(state, val) {
    // console.log(val);
    state.notification = val;
  },
  SETBARLIST(state, val) {
    state.menuBarList = val;
  },
  SET_MSUBJECTIDS(state, val) {
    state.monitorSubjectIds = val;
  },
  SET_QSUBJECTIDS(state, val) {
    state.qualitySubjectIds = val;
  },
  SET_TEACHER_ROLE_LIST(state, val) {
    state.teacherRoleList = val;
  },
  SET_SCHOOL_LIST(state, val) {
    state.schoolList = val;
  },
  setHeaderType(state) {
    if (state.headerType) {
      state.headerType = false;
    } else {
      state.headerType = true;
    }
    // console.log(val);
    // console.log("进入方法");
    // state.keepArr.push(val);
    // console.log(state.keepArr);
  },
  setKeepArr(state, val) {
    // console.log(val);
    // console.log("进入方法");
    state.keepArr.push(val);
    // console.log(state.keepArr);
  },
  delKeepArr(state, val) {
    if (!val) {
      state.keepArr = [];
    } else {
      state.keepArr.splice(state.keepArr.indexOf(val), 1);
    }
  },
  setErrApi(state, val) {
    state.errApi.type = val.type;
    if (val.text) {
      state.errApi.text = val.text;
    }
    // console.log(val);
  },
  setErrBox(state, val) {
    state.errBox.type = val.type;
    if (val.text) {
      state.errBox.text = val.text;
    }
    // state.errBox.type = val;
  },
  setIsPicDivide(state, value) {
    state.isPicDivide = value;
  },
  setIsUserInfo(state, value) {
    state.isUserInfo = value;
  },
};
const getters = {
  GET_Notification(state) {
    // console.log(state.notification);
    return state.notification;
  },
  GETBARLIST(state) {
    return state.menuBarList;
  },
  GET_SCHOOL_LIST(state) {
    return state.schoolList;
  },
  GET_MSUBJECTIDS(state) {
    return state.monitorSubjectIds;
  },
  GET_QSUBJECTIDS(state) {
    return state.qualitySubjectIds;
  },
  getSubjectKnow(state) {
    state.subjectKnow = getStore({ name: "subjectKnow" });
    return state.subjectKnow;
    // state.subjectKnow = val;
    // setStore("subjectKnow", val);
  },
  getScrollYWidth(state) {
    return state.scrollYWidth;
  },
  GET_TEACHER_ROLE_LIST(state) {
    return state.teacherRoleList;
  },
  getHeaderType(state) {
    return state.headerType;
  },
  getKeepArr(state) {
    return state.keepArr;
  },
  getErrApi(state) {
    return state.errApi;
  },
  getErrBox(state) {
    return state.errBox;
  },
  getIsPicDivide(state) {
    return state.isPicDivide;
  },
  getIsUserInfo(state) {
    return state.isUserInfo;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
