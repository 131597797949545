<template>
  <div
    class="home"
    :class="{
      'home-full-screen': $route.meta.fullScreen,
      iframe: $route.meta.iframe,
    }"
  >
    <!--       :class="{ 'new-view': bodyWidth }" -->
    <JZJXHeader
      v-if="!$route.meta.fullScreen"
      class="new-view"
      @newList="newList"
    ></JZJXHeader>

    <section
      id="kt_content"
      :key="newKeys"
      ref="ktContent"
      class="cnt-box"
      :class="{ isPicDivide: getIsPicDivide, isUserInfo: getIsUserInfo }"
    >
      <!--       :style="{
        'margin-left': getScrollYWidth + 'px',
        'padding-left': paddingWidthNum,
        'padding-right': paddingWidthNum,
      }" -->
      <!-- :style="{ 'margin-left': '-' + getScrollYWidth + 'px' }" -->
      <!-- 使用transition组件解决路由跳转页面卡顿感 -->
      <!-- <transition name="fade-transform" mode="out-in"> -->
      <!-- <keep-alive> -->
      <!-- 'padding-left': paddingWidthNum,
        'padding-right': paddingWidthNum, -->
      <!-- <router-view /> -->
      <!-- </keep-alive> -->
      <!-- </transition> -->

      <transition name="fade-transform">
        <keep-alive :include="allKeepAlive">
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
      </transition>
      <transition name="fade-transform">
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </transition>
    </section>
    <JZJXFooter
      v-if="!$route.meta.fullScreen && $route.name != 'xuekewang'"
    ></JZJXFooter>
    <KTScrollTop></KTScrollTop>
    <modelTips></modelTips>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import JZJXHeader from "@/components/header";
import JZJXFooter from "@/components/footer";
import KTScrollTop from "@/components/ScrollTop";
import modelTips from "@/components/versions/modelTips";
export default {
  name: "Home",
  components: {
    JZJXHeader,
    JZJXFooter,
    KTScrollTop,
    modelTips,
  },
  data() {
    return {
      allKeepAlive: [],
      newKeys: 1,
      bodyWidth: false,
      paddingWidthNum: "",
    };
  },
  computed: {
    ...mapGetters([
      "getIsPicDivide",
      "getIsUserInfo",
      "getKeepArr",
      "getScrollYWidth",
    ]),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.setView();
        });
      },
    },
    getKeepArr: {
      immediate: true,
      handler: function () {
        this.allKeepAlive = this.getKeepArr;
      },
    },
  },
  created() {
    // this.$nextTick(() => {
    //   this.setView();
    // });
  },
  mounted() {
    this.$nextTick(() => {
      this.setView();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.setView();
    });
  },
  methods: {
    hasScrollbar() {
      return (
        document.body.scrollHeight >
        (window.innerHeight || document.documentElement.clientHeight)
      );
    },
    setView() {
      // 如果是新页面 1720
      if (this.$route.meta.newViews) {
        document.getElementsByTagName("body").item(0).style.backgroundColor =
          "#f4f4f4";
        this.bodyWidth = true;
        // if (this.hasScrollbar()) {
        // this.paddingWidthNum = 90 - this.getScrollYWidth + "px";
        // } else {
        //   this.paddingWidthNum = "90px";
        // }
        // this.$nextTick(function () {
        //   this.$refs.ktContent.style.paddingLeft = this.paddingWidthNum;
        //   this.$refs.ktContent.style.paddingRight = this.paddingWidthNum;
        // });
      } else {
        // 以前的页面 1220
        document.getElementsByTagName("body").item(0).style.backgroundColor =
          "#ffffff";
        this.bodyWidth = false;
        // this.paddingWidthNum = 0;
      }
    },
    newList() {
      this.newKeys = new Date().getTime();
    },
  },
};
</script>
<style lang="scss">
.new-view {
  .menu-box {
    width: auto !important;
    // max-width: 1720px;
    padding: 0 90px;
  }
}
</style>
<style scoped lang="scss">
.home-full-screen {
  .cnt-box {
    margin-top: -72px;
  }
}
.home {
  // position: relative;
  /*
  enter-active 定义进入过渡的结束状态
  leave-active 定义离开过渡的结束状态
   */
  // .fade-transform-leave-active,
  // .fade-transform-enter-active {
  //   transition: all 0.3s;
  // }

  // /*
  // enter定义进入过渡的开始状态
  //  */
  // .fade-transform-enter {
  //   opacity: 0;
  //   // transform: translateY(10px);
  // }
  // /*
  // leave-to离场动画结束后的状态
  //  */
  // .fade-transform-leave-to {
  //   opacity: 0;
  //   // transform: translateY(-10px);
  // }
  .cnt-box {
    padding: 72px 73px 0;
    // width: 100vw;
    min-height: calc(100vh - 39px);
    // padding-left: calc(100vw - 100%);
  }
}
.iframe {
  padding-top: 32px;
  .cnt-box {
    padding: 0;

    min-height: auto;
  }
}
</style>
