export const sheet = [
  {
    path: "/sheet/list",
    name: "SheetList",
    meta: {
      name: "/sheet/list",
      newViews: true,
    },
    component: () => import("@/views/sheet/list.vue"),
  },
  {
    path: "/sheet/createSheet",
    name: "CreateSheet",
    meta: {
      name: "/sheet/list",
      newViews: true,
    },
    component: () => import("@/views/sheet/createSheet.vue"),
  },
];
