import axios from "axios";
// ,
import { serialize } from "@/core/util/util";
import { getStore, setStore, clearLogonStore } from "@/core/util/store";
import NProgress from "nprogress"; // progress bar
import errorCode from "@/core/const/errorCode";
import router from "@/router";
import { MessageBox } from "element-ui";
// import { Message } from "element-ui";
import store from "./store";
import "nprogress/nprogress.css";
import qs from "qs";
// import store from "@/store"; // progress bar style
// import { LOGOUT } from "@/core/services/store/auth.module";
axios.defaults.timeout = 6000 * 1000;
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
// 跨域请求，允许保存cookie
// axios.defaults.withCredentials = true;
// NProgress Configuration
NProgress.configure({
  showSpinner: false,
});

// HTTPrequest拦截
axios.interceptors.request.use(
  (config) => {
    //设置一下请求的弹框状态
    // store.state.errApi.type = false;
    // 存入一个本地时间戳  进行判断  大于10分钟直接到登录页面
    let time = getStore({ name: "lastRequestTime" });
    let newTime = parseInt(new Date().getTime() / 1000);
    let loginOnline = getStore({ name: "loginOnline" });
    if (!loginOnline) {
      if (!time) {
        clearLogonStore();
        router.push({ name: "login" });
        return Promise.reject(new Error("error"));
      } else {
        if (newTime - Number(time) > 7200) {
          clearLogonStore();
          router.push({ name: "login" });
          return Promise.reject(new Error("error"));
        } else {
          setStore({ name: "lastRequestTime", content: newTime });
        }
      }
    }

    store.commit("setErrApi", { type: false });
    // NProgress.start(); // start progress bar
    const TENANT_ID = getStore({ name: "tenantId" });
    const isToken = (config.headers || {}).isToken === false;
    const token = getStore({ name: "access_token" });
    const schoolId = getStore({ name: "school_id" });
    // console.log(token);
    if (token && !isToken) {
      config.headers["Authorization"] = "Bearer " + token; // token
    }
    if (TENANT_ID) {
      config.headers["TENANT-ID"] = TENANT_ID; // 租户ID
    }
    if (schoolId) {
      config.headers["SCHOOL-ID"] = schoolId; // 租户ID
    }
    // config.headers["DATE-TIME"] = new Date();
    // config.headers["Access-Control-Allow-Origin"] = "*";

    // headers中配置serialize为true开启序列化
    if (config.method === "post" && config.headers.serialize) {
      config.data = serialize(config.data);
      delete config.data.serialize;
    }

    if (config.method === "get") {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// HTTPresponse拦截
axios.interceptors.response.use(
  (res) => {
    NProgress.done();
    const status = Number(res.status) || 200;
    const message = res.data.msg || errorCode[status] || errorCode["default"];
    let statusArr = [401, 403, 200, 426, 302, 301];

    if (statusArr.indexOf(status) == -1) {
      store.commit("setErrApi", { type: true, text: "服务器内部错误!" });
      return Promise.reject(new Error(message));
    }

    if (
      res.config.url.split("?")[0] == "/auth/mobile/token/sms" &&
      status === 401
    ) {
      let str =
        res.data.data == "用户不存在"
          ? "用户未绑定，请使用账号密码登录后前往个人中心绑定。"
          : res.data.data;
      store.commit("setErrApi", {
        type: true,
        text: str,
      });
      return;
    }
    //
    if (status === 401) {
      store.commit("setErrBox", {
        type: true,
        text: "当前账号在其他地方登陆，请重新登录!",
      });
      return;
    }

    // console.log(res.data);
    if (status !== 200) {
      // console.log(res.data.msg);
      if (!res.data.msg || res.data.msg.length > 30) {
        store.commit("setErrApi", { type: true, text: "系统繁忙!" });
      } else {
        if (res.data.msg != "用户名不存在或者密码错误") {
          store.commit("setErrApi", { type: true, text: res.data.msg });
        }
      }
      // if (res.data.msg && status != 500) {
      //   store.commit("setErrApi", { type: true, text: res.data.msg });
      // }
      return Promise.reject(res.data);
    }
    if (
      res.config.url.startsWith("https://static.wtjy.com/") ||
      res.config.url.startsWith("http://static.wtjy.com/")
    ) {
      return res;
    }
    if (res.data.code == 1 && res.data.msg == null && res.data.data == null) {
      //改变状态，app.vue控制弹窗显示

      if (status === 500) {
        store.commit("setErrApi", { type: true, text: "系统繁忙!" });
      } else {
        //改变状态，app.vue控制弹窗显示

        store.commit("setErrApi", { type: true, text: "未查找到数据!" });
      }

      return Promise.reject(new Error(message));
    }
    if (res.data.code == 2) {
      MessageBox.alert(res.data.msg, "提示", {
        confirmButtonText: "返回列表",
        callback: () => {
          router.replace({ path: "/mark/mark-list" });
        },
      });
      return Promise.reject(res.data.code);
    }
    // console.log("继续执行了");
    if (status == 200 && res.data.code != 0 && res.data.code != undefined) {
      //改变状态，app.vue控制弹窗显示
      // 如果data为http开头，就是下载
      if (res.data.msg && res.data.msg.substr(0, 4) == "http") {
        store.commit("setErrApi", { type: true, text: "数据错误!" });
        var suffix = res.data.msg.substring(res.data.msg.lastIndexOf("."));
        const { href } = router.resolve({
          path: "/downFile",
          query: {
            url: res.data.msg,
            name: "错误信息" + suffix,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
      } else {
        // if (res.data.msg.length > 30) {
        //   store.commit("setErrApi", { type: true, text: "系统繁忙" });
        // } else {
        let resType = false;
        let reqmsg = res.data.msg || "";
        if (reqmsg.split(":")[0] == "Connection refused") {
          resType = true;
        }
        if (resType) {
          store.commit("setErrApi", {
            type: true,
            text: "服务器维护中，请稍后再试!",
          });
        } else {
          store.commit("setErrApi", {
            type: true,
            text: res.data.msg || "服务器内部错误！",
          });
        }
      }
      return Promise.reject(new Error(message));
    }
    return res;
  },
  (error) => {
    // console.log(error);
    //Error: error 自己中断的请求不报错 data.msg
    // 断网
    if (error == "Error: Network Error") {
      store.commit("setErrApi", {
        type: true,
        text: "当前网络连接已断开，请查看网络是否连接!",
      });
      return Promise.reject(new Error(error));
    }
    if (error && error.response && error.response.status == 503) {
      store.commit("setErrApi", {
        type: true,
        text: "服务器维护中，请稍后再试!",
      });
      return Promise.reject(new Error(error));
    }

    // Connection refused
    if (error != "Error: error") {
      store.commit("setErrApi", { type: true, text: "服务器内部错误!" });
      return Promise.reject(new Error(error));
    }
  }
);

export default axios;
