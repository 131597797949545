import { getstatuserauthteacherrole } from "@/core/api/academic/report";
export default {
  namespaced: true,
  state: {
    propData: null,
    topData: {},
    roleTypeList: [],
    statOnlineSettings: [],
    roleId: null,
    isMerge: false,
    scoreType: null,
    subjectId: null, // 赋分设置中的科目id
    subjectList: [],
    isNewExam: false,
  },
  mutations: {
    SET_UNION_SHEET_SCREEN_HEIGHT(STATE, PAYLOAD) {
      STATE.unionSheetScreenHeight = PAYLOAD;
    },
    SET_SUBJECT_LIST(STATE, PAYLOAD) {
      STATE.subjectList = PAYLOAD;
    },
    SET_SHOWAPEXCLUDEZERO(state, payload) {
      state.showApExcludeZero = payload;
    },
    SET_SUBJECT_ID(state, payload) {
      state.subjectId = payload;
    },
    IS_NEW_EXAM(state, payload) {
      state.isNewExam = payload;
    },
    SET_SCORE_TYPE(state, payload) {
      state.scoreType = payload;
    },
    SET_ISMERGE(state, payload) {
      state.isMerge = payload;
    },
    SET_PROP(state, payload) {
      state.propData = payload;
    },
    SET_STATONLINESETTINGS(state, { index, value }) {
      state.statOnlineSettings[index] = value;
    },
    SET_TOPDATA(state, payload) {
      state.topData = payload;
    },
    SET_ROLEROLELIST(state, payload) {
      state.roleTypeList = payload;
    },
    SET_ROLEID(state, payload) {
      state.roleId = payload;
    },
  },
  actions: {
    async getRoleTypeArr(context, payload) {
      const res = await getstatuserauthteacherrole({ id: payload });
      const { isRkjs, isBzr, isBkzz, isNjzz, isXld } = res.data.data;
      let data = [];
      if (isRkjs == 1) {
        data.push(1);
      }
      if (isBzr == 1) {
        data.push(2);
      }
      if (isBkzz == 1) {
        data.push(3);
      }
      if (isNjzz == 1) {
        data.push(4);
      }
      if (isXld == 1) {
        data.push(5);
      }
      return data;
    },
  },
};
