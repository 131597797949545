export const evaluate = [
  {
    path: "/evaluate/list",
    name: "Evaluate",
    meta: {
      name: "/evaluate/list",
      title: "素质考评",
      newViews: true,
    },
    component: () => import("@/views/evaluate/index.vue"),
  },
  {
    path: "/evaluate/evaluate-student",
    name: "EvaluateStudent",
    meta: {
      name: "/evaluate/list",
      title: "学生管理",
      newViews: true,
    },
    component: () => import("@/views/evaluate/evaluate-student.vue"),
  },
  {
    path: "/evaluate/Evaluation",
    name: "Evaluation",
    meta: {
      name: "/evaluate/list",
      title: "考评表管理",
      newViews: true,
    },
    component: () => import("@/views/evaluate/evaluation.vue"),
  },
  {
    path: "/evaluate/evaluate-distribute",
    name: "EvaluateDistribute",
    meta: {
      name: "/evaluate/list",
      title: "考评分配",
      newViews: true,
    },
    component: () => import("@/views/evaluate/evaluate-distribute.vue"),
  },
  {
    path: "/evaluate/evaluate-progress",
    name: "EvaluateProgress",
    meta: {
      name: "/evaluate/list",
      title: "考评分配",
      newViews: true,
    },
    component: () => import("@/views/evaluate/evaluate-progress.vue"),
  },
  {
    path: "/evaluate/evaluate-answer-sheet",
    name: "EvaluateAnswerSheet",
    meta: {
      name: "/evaluate/list",
      title: "开始考评",
      newViews: true,
    },
    component: () => import("@/views/evaluate/evaluate-answer-sheet.vue"),
  },
  {
    path: "/evaluate/evaluate-analyse",
    name: "EvaluateAnalyse",
    meta: {
      name: "/evaluate/list",
      title: "统计分析",
      newViews: true,
    },
    component: () => import("@/views/evaluate/evaluate-analyse.vue"),
  },
];
