export const unionBaseRouter = [
  // 联考基础信息页面
  {
    path: "/union/base/user",
    name: "union-base-user",
    meta: {
      tabName: "/newOther/newOther",
      newViews: true,
      title: "用户管理",
    },
    component: () => import("@/views/union/pages/user.vue"),
  },
  {
    path: "/union/base/authority",
    name: "union-base-authority",
    meta: {
      tabName: "/newOther/newOther",
      newViews: true,
      title: "权限管理",
    },
    component: () => import("@/views/union/pages/authority.vue"),
  },
  {
    path: "/union/base/params",
    name: "union-base-params",
    meta: {
      tabName: "/newOther/newOther",
      newViews: true,
      title: "参数设置",
    },
    component: () => import("@/views/union/pages/params.vue"),
  },
  {
    path: "/union/base/scan",
    name: "union-base-scan",
    meta: {
      tabName: "/newOther/newOther",
      newViews: true,
      title: "扫描点管理",
    },
    component: () => import("@/views/union/pages/scan.vue"),
  },
  {
    path: "/union/base/school",
    name: "union-base-school",
    meta: {
      tabName: "/newOther/newOther",
      newViews: true,
      title: "学校管理",
    },
    component: () => import("@/views/union/pages/school.vue"),
  },
  {
    path: "/union/base/log",
    name: "union-base-log",
    meta: {
      tabName: "/newOther/newOther",
      newViews: true,
      title: "操作日志",
    },
    component: () => import("@/views/union/pages/log.vue"),
  },
];
export const unionSheetRouter = [
  {
    path: "/union/welcome",
    name: "union-welcome",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "欢迎",
      subMenu: "/welcome",
    },
    component: () => import("@/views/union/academic/sheet/welcome.vue"),
  },
  {
    path: "/union/total/total",
    name: "union-total-total",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "总体情况分析",
      subMenu: "/total",
    },
    component: () => import("@/views/union/academic/sheet/total/total.vue"),
  },
  {
    path: "/union/total/area",
    name: "union-total-area",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "各地区情况分析",
      subMenu: "/total",
    },
    component: () => import("@/views/union/academic/sheet/total/area.vue"),
  },
  {
    path: "/union/total/totalSegment",
    name: "union-total-totalSegment",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "总分分数段分布",
      subMenu: "/total",
    },
    component: () =>
      import("@/views/union/academic/sheet/total/totalSegment.vue"),
  },
  {
    path: "/union/total/subjectSegment",
    name: "union-total-subjectSegment",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "单科分数段分布",
      subMenu: "/total",
    },
    component: () =>
      import("@/views/union/academic/sheet/total/subjectSegment.vue"),
  },
  {
    path: "/union/total/grade",
    name: "union-total-grade",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "成绩榜",
      subMenu: "/total",
    },
    component: () => import("@/views/union/academic/sheet/total/grade.vue"),
  },
  {
    path: "/union/total/level",
    name: "union-total-level",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "等级成绩榜",
      subMenu: "/total",
    },
    component: () => import("@/views/union/academic/sheet/total/level.vue"),
  },

  {
    path: "/union/comparison/total",
    name: "union-comparison-total",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "总分各项指标对比",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/total.vue"),
  },
  {
    path: "/union/comparison/subject",
    name: "union-comparison-subject",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "单科各项指标对比",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/subject.vue"),
  },
  {
    path: "/union/comparison/summary",
    name: "union-comparison-summary",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "各项指标对比汇总",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/summary.vue"),
  },
  {
    path: "/union/comparison/class",
    name: "union-comparison-class",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "班级各项指标对比",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/class.vue"),
  },
  {
    path: "/union/comparison/totalSegment",
    name: "union-comparison-totalSegment",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "总分分数段对比",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/totalSegment.vue"),
  },
  {
    path: "/union/comparison/subjectSegment",
    name: "union-comparison-subjectSegment",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "单科分数段对比",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/subjectSegment.vue"),
  },
  {
    path: "/union/comparison/totalN",
    name: "union-comparison-totalN",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "总分前N名对比",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/totalN.vue"),
  },
  {
    path: "/union/comparison/subjectN",
    name: "union-comparison-subjectN",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "前N名对比",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/subjectN.vue"),
  },
  {
    path: "/union/comparison/quality",
    name: "union-comparison-quality",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "学科质量对比分析",
      subMenu: "/comparison",
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/quality.vue"),
  },
  {
    path: "/union/comparison/rate",
    name: "union-comparison-rate",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "学科总体评价",
      subMenu: "/comparison",
    },
    component: () => import("@/views/union/academic/sheet/comparison/rate.vue"),
  },
  {
    path: "/union/quesitons/data",
    name: "union-quesitons-data",
    meta: {
      tabName: "/newOther/newOther",
      newViews: true,
      title: "试题数据分析",
      subMenu: "/questions",
    },
    component: () => import("@/views/union/academic/sheet/questions/data.vue"),
  },
  {
    path: "/union/quesitons/choice",
    name: "union-quesitons-choice",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "选择题数据分析",
      subMenu: "/questions",
    },
    component: () =>
      import("@/views/union/academic/sheet/questions/choice.vue"),
  },
  {
    path: "/union/quesitons/notChoice",
    name: "union-quesitons-notChoice",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "非选择题数据分析",
      subMenu: "/questions",
    },
    component: () =>
      import("@/views/union/academic/sheet/questions/notChoice.vue"),
  },
  {
    path: "/union/quesitons/options",
    name: "union-quesitons-options",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "选做题作答分析",
      subMenu: "/questions",
    },
    component: () =>
      import("@/views/union/academic/sheet/questions/options.vue"),
  },
  {
    path: "/union/quesitons/school",
    name: "union-quesitons-school",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "常用指标各校对比",
      subMenu: "/questions",
    },
    component: () =>
      import("@/views/union/academic/sheet/questions/school.vue"),
  },
  {
    path: "/union/quesitons/class",
    name: "union-quesitons-class",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "常用指标班级对比",
      subMenu: "/questions",
    },
    component: () => import("@/views/union/academic/sheet/questions/class.vue"),
  },
  {
    path: "/union/quesitons/detail",
    name: "union-quesitons-detail",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "试题得分详细分布",
      subMenu: "/questions",
    },
    component: () =>
      import("@/views/union/academic/sheet/questions/detail.vue"),
  },
  {
    path: "/union/paper/reliability",
    name: "union-paper-reliability",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "试卷信度统计",
      subMenu: "/paper",
    },
    component: () =>
      import("@/views/union/academic/sheet/paper/reliability.vue"),
  },
  {
    path: "/union/paper/subject",
    name: "union-paper-subject",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "学科得分率分布",
      subMenu: "/paper",
    },
    component: () => import("@/views/union/academic/sheet/paper/subject.vue"),
  },
  {
    path: "/union/paper/diffcultlyBranch",
    name: "union-paper-diffcultlyBranch",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "难度指数分布图",
      subMenu: "/paper",
    },
    component: () =>
      import("@/views/union/academic/sheet/paper/diffcultlyBranch.vue"),
  },
  {
    path: "/union/paper/total",
    name: "union-paper-total",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "试题汇总分析",
      subMenu: "/paper",
    },
    component: () => import("@/views/union/academic/sheet/paper/total.vue"),
  },
  {
    path: "/union/paper/diffcultly",
    name: "union-paper-diffcultly",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "试题难度分布",
      subMenu: "/paper",
    },
    component: () =>
      import("@/views/union/academic/sheet/paper/diffcultly.vue"),
  },
  {
    path: "/union/paper/discrimination",
    name: "union-paper-discrimination",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "区分度等级分布",
      subMenu: "/paper",
    },
    component: () =>
      import("@/views/union/academic/sheet/paper/discrimination.vue"),
  },
  {
    path: "/union/characteristic/typical",
    name: "union-characteristic-typical",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "典型试卷",
      subMenu: "/characteristic",
    },
    component: () =>
      import("@/views/union/academic/sheet/characteristic/typical.vue"),
  },
  {
    path: "/union/characteristic/highScore",
    name: "union-characteristic-highScore",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "高分小题",
      subMenu: "/characteristic",
    },
    component: () =>
      import("@/views/union/academic/sheet/characteristic/highScore.vue"),
  },
  {
    path: "/union/online/total",
    name: "union-online-total",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "总体情况分析",
      subMenu: "/online",
    },
    component: () => import("@/views/union/academic/sheet/online/total.vue"),
  },
  {
    path: "/union/online/school",
    name: "union-online-school",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "各校总分上线情况",
      subMenu: "/online",
    },
    component: () => import("@/views/union/academic/sheet/online/school.vue"),
  },
  {
    path: "/union/online/subject",
    name: "union-online-subject",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "各校单科上线情况",
      subMenu: "/online",
    },
    component: () => import("@/views/union/academic/sheet/online/subject.vue"),
  },
  {
    path: "/union/online/totalSubject",
    name: "union-online-totalSubject",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "各校各学科上线情况",
      subMenu: "/online",
    },
    component: () =>
      import("@/views/union/academic/sheet/online/totalSubject.vue"),
  },
  {
    path: "/union/online/create",
    name: "union-online-create",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "各校各学科上线情况",
      subMenu: "/online",
    },
    component: () => import("@/views/union/academic/sheet/online/create.vue"),
  },
  {
    path: "/union/school/grade",
    name: "union-school-grade",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "年级成绩榜",
      subMenu: "/school",
    },
    component: () => import("@/views/union/academic/sheet/school/grade.vue"),
  },
  {
    path: "/union/school/gradeLevel",
    name: "union-school-gradeLevel",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "年级等级榜",
      subMenu: "/school",
    },
    component: () =>
      import("@/views/union/academic/sheet/school/gradeLevel.vue"),
  },
  {
    path: "/union/school/class",
    name: "union-school-class",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "班级成绩榜",
      subMenu: "/school",
    },
    component: () => import("@/views/union/academic/sheet/school/class.vue"),
  },
  {
    path: "/union/school/classLevel",
    name: "union-school-classLevel",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "班级等级榜",
      subMenu: "/school",
    },
    component: () =>
      import("@/views/union/academic/sheet/school/classLevel.vue"),
  },
  {
    path: "/union/school/target",
    name: "union-school-target",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "班级各项指标对比",
      subMenu: "/school",
    },
    component: () => import("@/views/union/academic/sheet/school/target.vue"),
  },
  {
    path: "/union/school/segment",
    name: "union-school-segment",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "班级分数段对比",
      subMenu: "/school",
    },
    component: () => import("@/views/union/academic/sheet/school/segment.vue"),
  },
  {
    path: "/union/school/paper",
    name: "union-school-paper",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "试卷分析",
      subMenu: "/school",
    },
    component: () => import("@/views/union/academic/sheet/school/paper.vue"),
  },
  {
    path: "/union/school/slip",
    name: "union-school-slip",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "成绩条",
      subMenu: "/school",
    },
    component: () => import("@/views/union/academic/sheet/school/slip.vue"),
  },
  {
    path: "/union/importToSchool/index",
    name: "union-importToSchool-index",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "导入到学校",
      subMenu: "/importToSchool",
    },
    component: () =>
      import("@/views/union/academic/sheet/importToSchool/index.vue"),
  },
  {
    path: "/union/muchExport/index",
    name: "union-muchExport-index",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "批量导出",
      subMenu: "/muchExport",
    },
    component: () =>
      import("@/views/union/academic/sheet/muchExport/index.vue"),
  },
  {
    path: "/union/senior/paper",
    name: "union-senior-paper",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "导出考生答题卡",
      subMenu: "/senior",
    },
    component: () => import("@/views/union/academic/sheet/senior/paper.vue"),
  },
  {
    path: "/union/senior/data",
    name: "union-senior-data",
    meta: {
      tagName: "academic",
      newViews: true,
      title: "导出原始数据",
      subMenu: "/senior",
    },
    component: () => import("@/views/union/academic/sheet/senior/data.vue"),
  },
];
export const union = [
  {
    path: "/union/base",
    name: "union-base",
    meta: {
      newViews: true,
      title: "基础信息管理",
      tabName: "/newOther/newOther",
    },
    redirect: "/union/base/user",
    component: () => import("@/views/union/index.vue"),
    children: [...unionBaseRouter],
  },
];
