const env = process.env;
// console.log(env);
let adminUrl = ""; // 管理端端网址
if (env.NODE_ENV === "development") {
  // 开发环境
  // 本地用户端网址
  adminUrl = "http://localhost:8080/";
} else if (env.NODE_ENV === "production") {
  // 生产环境
  if (env.VUE_APP_EDITION === "prod") {
    // 正式服用户端网址
    adminUrl = "http://cms.wtjy.com/";
  } else if (env.VUE_APP_EDITION === "test") {
    // 测试服用户端网址
    adminUrl = "http://test-cms.wtjy.com/";
  }
} else if (env.NODE_ENV === "test") {
  // 测试环境
  // 暂设为本地用户端网址
  adminUrl = "http://localhost:8080/";
}
// alert(env.BASE_URL);
// alert(env.NODE_ENV);
// alert(env.VUE_APP_EDITION);
// console.log(env);
export { adminUrl };
