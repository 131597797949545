export default {
  state: {
    // 当前异常处理
    indexScanError: {},
    // 试题列表
    honeWorkQuestionList: [],
  },
  getters: {
    GET_SCAN_ERR(state) {
      return state.indexScanError;
    },
    // 获取试题列表
    GET_HW_QUESTION_LIST(state) {
      return state.honeWorkQuestionList;
    },
  },
  actions: {},
  mutations: {
    SET_SCAN_ERR(state, val) {
      state.indexScanError = val;
    },
    // 设置试题列表
    SET_HW_QUESTION_LIST(state, val) {
      state.honeWorkQuestionList = val;
    },
  },
};
