const getters = {
  userInfo: (state) => state.auth.userInfo,
  access_token: (state) => state.auth.access_token,
  school_id: (state) => state.auth.school_id,
  refresh_token: (state) => state.auth.refresh_token,
  expires_in: (state) => state.auth.expires_in,
  roles: (state) => state.auth.roles,
  permissions: (state) => state.auth.permissions,
  menu: (state) => state.auth.menu,
  menuAll: (state) => state.auth.menuAll,
  isAuthenticated: (state) => state.auth.isAuthenticated,
};
export default getters;
