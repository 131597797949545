// 审题
export const examine = [
  {
    path: "/examine/list",
    name: "/examine/list",
    meta: {
      name: "/examine/list",
      newViews: true,
      title: "审题",
    },
    component: () => import("@/views/examine/list.vue"),
  },

  {
    path: "/examine/auditStatistics",
    name: "/examine/auditStatistics",
    meta: {
      name: "/examine/list",
      newViews: true,
      title: "审核变式题 / 审题统计",
    },
    component: () => import("@/views/examine/auditStatistics.vue"),
  },
];
