import { validatenull } from "@/core/util/validate";

// key: 'jzjx-', 配置主键,目前用于存储
export const keyName = "jzjx-";

/**
 * 存储浏览器Storage
 */
export const setStore = (params = {}) => {
  let { name, content, type } = params;
  name = keyName + name;
  const obj = {
    dataType: typeof content,
    content: content,
    type: type,
    datetime: new Date().getTime(),
  };
  if (type) {
    window.sessionStorage.setItem(name, JSON.stringify(obj));
  } else {
    window.localStorage.setItem(name, JSON.stringify(obj));
  }
};

/**
 * 获取浏览器Storage
 */
export const getStore = (params = {}) => {
  let { name, debug } = params;
  name = keyName + name;
  let obj = {};
  let content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch (e) {
    return obj;
  }
  if (debug) {
    return obj;
  }
  if (obj.dataType === "string") {
    content = obj.content;
  } else if (obj.dataType === "number") {
    content = Number(obj.content);
  } else if (obj.dataType === "boolean") {
    content = obj.content;
  } else if (obj.dataType === "object") {
    content = obj.content;
  }
  return content;
};

/**
 * 内部使用方法
 */
const getStoreInternal = (params = {}) => {
  let { name, debug } = params;
  let obj = {};
  let content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch (e) {
    return obj;
  }
  if (debug) {
    return obj;
  }
  if (obj.dataType === "string") {
    content = obj.content;
  } else if (obj.dataType === "number") {
    content = Number(obj.content);
  } else if (obj.dataType === "boolean") {
    content = obj.content;
  } else if (obj.dataType === "object") {
    content = obj.content;
  }
  return content;
};

/**
 * 删除浏览器Storage
 */
export const removeStore = (params = {}) => {
  let { name, type } = params;
  name = keyName + name;
  if (type) {
    window.sessionStorage.removeItem(name);
  } else {
    window.localStorage.removeItem(name);
  }
};

/**
 * 获取全部浏览器Storage
 */
export const getAllStore = (params = {}) => {
  const list = [];
  const { type } = params;
  if (type) {
    for (let i = 0; i <= window.sessionStorage.length; i++) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStoreInternal({
          name: window.sessionStorage.key(i),
          type: "session",
        }),
      });
    }
  } else {
    for (let i = 0; i <= window.localStorage.length; i++) {
      list.push({
        name: window.localStorage.key(i),
        content: getStoreInternal({
          name: window.localStorage.key(i),
        }),
      });
    }
  }
  return list;
};

/**
 * 清空全部浏览器Storage
 */
export const clearStore = (params = {}) => {
  // console.log(type);
  const { type } = params;

  let name = keyName + "template_version";
  let version = window.localStorage.getItem(name);
  if (type) {
    window.sessionStorage.clear();
  } else {
    window.localStorage.clear();
  }
  // 添加一个判断  如果用户存在版本  就在清除后添加回去

  // console.log(version);
  if (version) {
    version = JSON.parse(version).content;
    const obj = {
      dataType: typeof version,
      content: version,
      type: type,
      datetime: new Date().getTime(),
    };
    window.localStorage.setItem(name, JSON.stringify(obj));
  }
};

//清除登录缓存
export const clearLogonStore = () => {
  window.sessionStorage.clear();

  window.localStorage.removeItem("jzjx-school_id");
  window.localStorage.removeItem("jzjx-userInfo");
  window.localStorage.removeItem("jzjx-router-list");
  window.localStorage.removeItem("jzjx-levelId");
  window.localStorage.removeItem("jzjx-subjectId");
  window.localStorage.removeItem("jzjx-schoolSubjectList");
  window.localStorage.removeItem("jzjx-user-message");
  window.localStorage.removeItem("jzjx-access_token");
  window.localStorage.removeItem("jzjx-refresh_token");
  window.localStorage.removeItem("applicationList");
  window.localStorage.removeItem("jzjx-menu");
  window.localStorage.removeItem("jzjx-notification");
  window.localStorage.removeItem("scan-parameter");
};
