<template>
  <div>
    <div v-if="showModel" class="modelTips">
      <div class="tips-box">
        <div class="tips-model">
          <div class="tips-time">{{ time }}s后自动关闭</div>
          <div class="school-name">{{ userInfo.schoolName }}，你好!</div>
          <!-- 学校版本：0-默认版 1-基础版 2-标准版 3-精准教学 -->
          <div v-if="userInfo.schoolVersion == 1" class="cnt-msg">
            欢迎使用文通云（基础版）
          </div>
          <!--  v-if="userInfo.schoolVersion == 2" -->
          <!-- <div v-if="userInfo.schoolVersion == 2" class="cnt-msg">
          欢迎使用文通云（标准版）
        </div> -->
          <div class="btn-box">
            <el-button type="primary" @click="closeModel()">开始使用</el-button>
          </div>
          <div class="btn-box">
            <el-checkbox v-model="showType">下次不再提示弹窗</el-checkbox>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="文通云产品维护公告"
      :visible.sync="versionUpdating"
      class="version-updating"
      width="548px"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="massage edit-scroll-style">
        <div
          style="white-space: pre-wrap; line-height: 21px"
          v-html="notification"
        ></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setVersiontion()">
          我知道了
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStore, setStore } from "@/core/util/store";
export default {
  name: "ModelTips",
  data() {
    return {
      time: 10,
      notification: "",
      userInfo: {},
      showType: true,
      showModel: false,
      versionUpdating: false,
    };
  },
  created() {},
  mounted() {
    this.getShowVison();
  },
  methods: {
    setVersiontion() {
      this.versionUpdating = false;
      setTimeout(() => {
        this.initTips();
      }, 500);
      setStore({ name: "Version", content: new Date().getTime() });
    },
    getShowVison() {
      let oldTime = getStore({ name: "Version" });
      this.notification = getStore({ name: "notification" });
      let time = new Date().getTime();
      if (!oldTime || time - Number(oldTime) > 60 * 60 * 24 * 1000) {
        if (!this.notification) {
          this.initTips();
          return;
        }
        this.versionUpdating = true;
      } else {
        this.initTips();
      }
    },
    closeModel() {
      this.showModel = false;
      if (this.showType) {
        this.schoolIds.push(this.userInfo.userId);
        setStore({
          name: "showSchoolIds",
          content: [...new Set(this.schoolIds)],
        });
      }
      //   用戶在当前窗口打开后 不退出登录 就判断不打开
      this.schoolIdsCopy.push(this.userInfo.userId);
      setStore({
        name: "schoolIdsCopy",
        content: [...new Set(this.schoolIdsCopy)],
        type: 1,
      });

      clearInterval(this.timeObj);
    },
    initTips() {
      this.schoolIds =
        getStore({
          name: "showSchoolIds",
        }) || [];
      this.schoolIdsCopy =
        getStore({
          name: "schoolIdsCopy",
          type: 1,
        }) || [];

      this.userInfo = getStore({
        name: "userInfo",
      });
      if (
        this.schoolIds.indexOf(this.userInfo.userId) != -1 ||
        this.schoolIdsCopy.indexOf(this.userInfo.userId) != -1 ||
        this.userInfo.schoolVersion != 1
      ) {
        return;
      }

      this.showModel = true;

      this.timeObj = setInterval(() => {
        this.time = this.time - 1;
        if (this.time == 0) {
          clearInterval(this.timeObj);
          this.closeModel();
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.version-updating {
  .massage {
    max-height: 134px;
  }

  ::v-deep .el-dialog__header {
    background-image: url("~@/assets/header/headerShow.png");
    background-size: 100% 100%;
    height: 80px;
    line-height: 80px;
    padding: 0;
    padding-left: 24px;
  }

  ::v-deep .el-dialog__footer {
    text-align: center;
  }
}
.modelTips {
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999999;

  .tips-box {
    background-color: rgba(37, 38, 45, 0.6);
    position: relative;
    width: 100%;
    height: 100vh;
    .tips-model {
      position: absolute;
      width: 593px;
      height: 372px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url("~@/assets/versions/model-bg.png") no-repeat;
      background-size: 100% 100%;
      padding: 70px 34px;
    }
    .tips-time {
      display: flex;
      justify-content: flex-end;
      color: #3e4551;
    }
    .school-name {
      line-height: 33px;
      font-size: 24px;
      color: #0a1119;
      font-weight: 600;
      text-align: center;
      margin-top: 26px;
    }
    .cnt-msg {
      line-height: 33px;
      font-size: 24px;
      color: #0a1119;
      font-weight: 400;
      text-align: center;
      margin-top: 18px;
      padding-bottom: 24px;
    }
    .btn-box {
      margin-top: 16px;
      text-align: center;
    }
  }
  //   background-color: ;
}
</style>
