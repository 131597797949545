import Vue from "vue";
const unionForShowByField = {
  install: function () {
    Vue.directive("isUnionShow", {
      bind: function (el, binding, vnode) {
        let that = vnode.context;
        if (that.$route.query.examType == 1) {
          const { type } = binding.value;
          if (type) {
            if (type == "length" && that.topData.topInfoOnlineVOs.length == 0) {
              el.style.display = "none";
            }
          } else {
            if (that.topData[binding.value] != 1) {
              el.style.display = "none";
            }
          }
        }
      },
    });
    Vue.directive("isDashBoardUnionShow", {
      bind: function (el, binding, vnode) {
        let that = vnode.context;
        if (that.examObj.examType == 1) {
          const { type } = binding.value;
          if (type) {
            if (type == "length" && that.topData.topInfoOnlineVOs.length == 0) {
              el.style.display = "none";
            }
          } else {
            if (that.topData[binding.value] != 1) {
              el.style.display = "none";
            }
          }
        }
      },
    });
  },
};
export default unionForShowByField;
