<template>
  <!-- begin::Scrolltop -->
  <!-- <div id="kt_scrolltop" ref="kt_scrolltop" class="scrolltop"> -->
  <el-backtop :visibility-height="100" :right="40" :bottom="40">
    <img src="../assets/imgs/components/Up-2.svg" alt="" />
  </el-backtop>
</template>

<script>
export default {
  name: "KTScrollTop",
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.el-backtop {
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: #b3d1ff;
  -webkit-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  /* opacity: 0; */
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  border-radius: 0.42rem !important;
  &:hover {
    background-color: $primary-color;
  }
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  text-align: center;

  img {
    vertical-align: inherit;
  }
}
</style>
