const path = require("path");
let routerArray = [];
// console.log(routerArray);
export function useRouter() {
  const routerFiles = require.context("@/router/modules", true, /(.js$)/);
  let fileKeys = routerFiles.keys();
  fileKeys.map((item) => {
    const name = path.basename(item, ".js");
    routerArray = routerArray.concat(routerFiles(item)[name]);
  });
  return routerArray;
}
